export const alertTypes = {
  error: "error",
  success: "success",
};

export const validationTypes = {
  alphanumeric: "Alphanumeric",
  alphabetic: "Alphabetic",
  numeric: "Numeric",
};

export const typeValues = {
  password: "Password",
  singleLine: "Single Line",
  multipleLine: "Multiple Lines",
};

export const formTypes = {
  filledUpForm: "filledUpForm",
  requestForm: "requestForm",
};

export function getDistinctObjects(array, key) {
  const uniqueKeys = new Set();
  return array.filter((item) => {
    const keyValue = item[key];
    if (uniqueKeys.has(keyValue)) {
      return false;
    } else {
      uniqueKeys.add(keyValue);
      return true;
    }
  });
}

export function getDistinctSubRows(mainArray, subRowsKey, distinctKey) {
  return mainArray.map((item) => {
    const distinctSubRows = getDistinctObjects(item[subRowsKey], distinctKey);
    return {
      ...item,
      [subRowsKey]: distinctSubRows,
    };
  });
}

export const customShippingAddressElemObj = [
  { name: "FirstName", label: "First Name", type: "text" },
  { name: "LastName", label: "Last Name", type: "text" },
  { name: "ShippingAddress1", label: "Street Address", type: "text" },
  { name: "ShippingSuite", label: "Apt, Suite", type: "text" },
  { name: "ShippingZip", label: "Zip Code", type: "text" },
  { name: "ShippingCity", label: "City", type: "text" },
  {
    name: "ShippingPhone",
    label: "Phone Number",
    type: "text",
  },
  { name: "ShippingEmail", label: "Email", type: "email" },
];

export const MEDIA_BASE_URL = process.env.REACT_APP_MEDIA_BASE_URL;
export const CAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_SITEKEY;
export const frontBaseUrl = process.env.REACT_APP_FRONT_BASE_URL;
// export const adminBaseUrl = process.env.REACT_APP_ADMIN_BASE_URL;
