import { Fragment } from "react";
import Product from "../../../Product";

const ProductSection = ({ products, storeId, section, promotionalProductCountRef, eachTypeProductCount, seteachTypeProductCount }) => {
  return (
    <div className="relative w-full">
      <p className="text-xl mt-3 text-black font-bold">{section === "promotionalProduct" ? "Fixed products included with each order:" : (section === "bundleProduct") ? "Please select ONE product from this group:" : "Please select ONE product from this group:"}</p>
      <ul className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-[15px] mb-8 mt-1">
        {
          section === "bundleProduct" ? <>
            {
              (products && Array.isArray(products)) && products.map((crntBundle, bundleIndex) => <Fragment key={bundleIndex}>
                {/* <li className="col-span-full font-medium">{crntBundle?.bundleName}</li> */}
                {
                  crntBundle?.subRows && Array.isArray(crntBundle?.subRows) && crntBundle?.subRows.map((bundleProduct, index) => <li className="text-center" key={index}>
                    <Product product={bundleProduct} storeId={storeId} productIndex={`bundleProduct-${bundleIndex}-${index}`} section={section} promotionalProductCountRef={promotionalProductCountRef} seteachTypeProductCount={seteachTypeProductCount} eachTypeProductCount={eachTypeProductCount} variableForProductTypeSelecte={`bundle_${bundleIndex + 1}`} />
                  </li >)
                }
              </Fragment>)
            }
          </> : <>
            {products.map((product, index) => (
              <li className="text-center" key={index}>
                <Product product={product} storeId={storeId} productIndex={index} section={section} promotionalProductCountRef={promotionalProductCountRef} seteachTypeProductCount={seteachTypeProductCount} eachTypeProductCount={eachTypeProductCount} variableForProductTypeSelecte={section} />
              </li>
            ))}
          </>
        }
      </ul >
    </div >
  );
};

export default ProductSection;
