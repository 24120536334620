import { Fragment, useContext, useEffect, useState } from "react";

import { FormContext } from "../../contexts/FormContext";
import { checkValidation } from "../../validations/checkValidation";
import fieldTypes from "../../data/fieldTypes";

import ProductSection from "../section/pages/formBuilderPage/ProductSection";
import Input from "./Input";

const FormContent = ({
  pageContent,
  handleInputChange,
  validationErrors,
  setValidationErrors,
  fieldValues,
  currentPage,
  setFieldValues,
  formLabelFontSize,
  products,
  promotionalProducts,
  storeId,
  promotionalProductCountRef,
  bundleRequiredData
}) => {
  const { isUseTemplate, formData } = useContext(FormContext);
  const [eachTypeProductCount, seteachTypeProductCount] = useState({
    product: 0,
    promotionalProduct: 0,
  })
  const handleDependentFormFieldChange = (id, value, validations, parentId) => {
    try {
      if (validations && Object.keys(validations).length) {
        // validation starts
        const { required, name, type, minLength } = validations;
        // check for validation based on type
        const errorMessage = checkValidation(
          { name, required, type, minLength },
          value
        );
        // if validation fails
        if (errorMessage) {
          const foundError = validationErrors.find((el) => el.id === id);
          // if error already present change the message (because validations could be multiple)

          if (foundError) {
            foundError.message = errorMessage;
            setValidationErrors(() =>
              validationErrors.map((ele) => {
                if (ele.id === id) {
                  return { id, message: errorMessage };
                } else {
                  return ele;
                }
              })
            );
          } else {
            // if error not already present add it
            setValidationErrors([
              ...validationErrors,
              { id, message: errorMessage },
            ]);
          }
        } else {
          // if validation succeed remove the object from validation error
          const filteredErrors = validationErrors.filter((el) => el.id !== id);
          setValidationErrors(filteredErrors);
        }
        // validation ends
      }
      const fValues = JSON.parse(JSON.stringify(fieldValues));

      fValues.pages[currentPage].rows.forEach((row) => {
        row.elements.forEach((element) => {
          if (element.id === parentId) {
            const formsAdded = element?.fieldOptions?.value;
            if (formsAdded.length) {
              formsAdded.forEach((form) => {
                form.forEach((rows) => {
                  rows.forEach((row) => {
                    row.elements.forEach((elem) => {
                      if (elem.id === id) {
                        elem.fieldOptions.value = value;
                      }
                    });
                  });
                });
              });
            }
          }
        });
      });
      setFieldValues(fValues);
    } catch (error) {
      console.log("---error caught--", error);
    }
  };

  const handleDeleteForm = (index, parentId) => {
    const fValues = JSON.parse(JSON.stringify(fieldValues));
    fValues.pages[currentPage].rows.forEach((row) => {
      row.elements.forEach((element) => {
        if (element.id === parentId) {
          const formsAdded = element?.fieldOptions?.value;
          formsAdded.splice(index, 1);
        }
      });
    });
    setFieldValues(fValues);
  };

  const findProduct = (data) => data.some(group => group?.elements?.some(element => element?.fieldOptions?.label === 'product'));

  return (
    <div>
      {!isUseTemplate && (
        <h1 className="text-center text-3xl mb-3 mt-1 hidden">
          {pageContent?.page_name}
        </h1>
      )}

      {/* row wrapper */}
      {pageContent?.rows?.map((row, index) => (
        <div className="flex mx-[-15px]" key={`row${index}`}>

          {row.elements.map((element, innerIndex) => {
            if (
              element?.name === "button" &&
              element?.fieldOptions?.dependencies?.value
            ) {
              const Component = fieldTypes[element.name];
              return (
                <div className="flex-1 mx-[15px]" key={innerIndex}>
                  <div className="my-2">
                    {element?.fieldOptions?.value?.map((form, ind) => {
                      return (
                        <div
                          key={`form${ind}`}
                          className={
                            element?.fieldOptions?.background_class || ""
                          }
                        >
                          <div className="flex items-center">
                            <button
                              onClick={() => {
                                handleDeleteForm(index, element.id);
                              }}
                              className="material-icons text-base mr-2 cursor-pointer"
                            >
                              cancel
                            </button>
                            <div>Item {index + 1}</div>
                          </div>
                          {form.map((rows) => {
                            return rows.map((row, i) => {
                              return (
                                <div
                                  className="flex mx-[-15px]"
                                  key={`row${i}`}
                                >
                                  {row.elements.map((el, __index) => {
                                    const EL = fieldTypes[el.name];
                                    return (
                                      <Fragment key={__index}>
                                        <EL
                                          value={el.fieldOptions.value}
                                          parentId={element.id}
                                          choiceValue={
                                            el.fieldOptions.choice_value
                                          }
                                          dependencies={
                                            el.fieldOptions.dependencies
                                          }
                                          setFieldValues={setFieldValues}
                                          key={el.id}
                                          currentPage={currentPage}
                                          placeholder={
                                            el.name === "country"
                                              ? "Please enter country"
                                              : el.fieldOptions.placeholderText
                                          }
                                          label={
                                            el.name === "country"
                                              ? "Country"
                                              : el.fieldOptions.label
                                          }
                                          required={el.fieldOptions.isRequired}
                                          name={el.name}
                                          options={el.fieldOptions?.choice || {}}
                                          isReadOnly={el.fieldOptions?.isReadOnly}
                                          defaultValue={
                                            el.fieldOptions?.defaultValue
                                          }
                                          handleChange={
                                            handleDependentFormFieldChange
                                          }
                                          id={el.id}
                                          validationErrors={validationErrors}
                                          setValidationErrors={
                                            setValidationErrors
                                          }
                                          type={el.fieldOptions?.type_value}
                                          validationType={
                                            el.fieldOptions
                                              ?.formatValidation_value
                                          }
                                          helperText={el.fieldOptions?.helpText}
                                          numberOfCharacters={
                                            el.fieldOptions?.numberOfCharacters
                                          }
                                          fieldValues={fieldValues}
                                        />
                                      </Fragment>
                                    );
                                  })}
                                </div>
                              );
                            });
                          })}
                        </div>
                      );
                    })}
                  </div>
                  <Component
                    icon={element.fieldOptions.icon}
                    classN={element?.fieldOptions?.class || ""}
                    choiceValue={element.fieldOptions.choice_value}
                    dependencies={element.fieldOptions.dependencies}
                    setFieldValues={setFieldValues}
                    key={element.id}
                    currentPage={currentPage}
                    placeholder={
                      element.name === "country"
                        ? "Please enter country"
                        : element.fieldOptions.placeholderText
                    }
                    label={
                      element.name === "country"
                        ? "Country"
                        : element.fieldOptions.label
                    }
                    required={element.fieldOptions.isRequired}
                    name={element.name}
                    options={element.fieldOptions?.choice || {}}
                    isReadOnly={element.fieldOptions?.isReadOnly}
                    defaultValue={element.fieldOptions?.defaultValue}
                    handleChange={handleInputChange}
                    id={element.id}
                    validationErrors={validationErrors}
                    setValidationErrors={setValidationErrors}
                    type={element.fieldOptions?.type_value}
                    validationType={
                      element.fieldOptions?.formatValidation_value
                    }
                    helperText={element.fieldOptions?.helpText}
                    numberOfCharacters={
                      element.fieldOptions?.numberOfCharacters
                    }
                    fieldValues={fieldValues}
                  />
                </div>
              );
            } else {
              const Component = fieldTypes[element?.name];
              return (
                <Fragment key={element?.id}>
                  {Component ? (
                    <>
                      <Component
                        isTranspose={element?.fieldOptions?.isTransponse}
                        formLabelFontSize={formLabelFontSize}
                        className={element?.fieldOptions?.class}
                        value={element.fieldOptions.value}
                        choiceValue={element.fieldOptions.choice_value}
                        dependencies={element.fieldOptions.dependencies}
                        setFieldValues={setFieldValues}
                        key={element.id}
                        currentPage={currentPage}
                        placeholder={
                          element.name === "country"
                            ? "Please enter country"
                            : element.fieldOptions.placeholderText
                        }
                        label={
                          element.name === "country"
                            ? "Country"
                            : element.fieldOptions.label
                        }
                        required={element.fieldOptions.isRequired}
                        name={element.name}
                        options={element.fieldOptions?.choice || {}}
                        isReadOnly={element.fieldOptions?.isReadOnly}
                        defaultValue={element.fieldOptions?.defaultValue}
                        handleChange={handleInputChange}
                        id={element.id}
                        validationErrors={validationErrors}
                        setValidationErrors={setValidationErrors}
                        type={element.fieldOptions?.type_value}
                        validationType={
                          element.fieldOptions?.formatValidation_value
                        }
                        helperText={element.fieldOptions?.helpText}
                        numberOfCharacters={
                          element.fieldOptions?.numberOfCharacters
                        }
                        fieldValues={fieldValues}
                        isShow={element?.fieldOptions?.isShow}
                      />
                    </>
                  ) : (
                    <Input
                      formLabelFontSize={formLabelFontSize}
                      value={element?.fieldOptions?.value}
                      key={element?.id}
                      currentPage={currentPage}
                      placeholder={element?.fieldOptions?.placeholderText}
                      label={element?.fieldOptions?.label}
                      required={element?.fieldOptions?.isRequired}
                      name={element?.name}
                      isReadOnly={element?.fieldOptions?.isReadOnly}
                      defaultValue={element?.fieldOptions?.defaultValue}
                      handleChange={handleInputChange}
                      id={element?.id}
                      validationErrors={validationErrors}
                      setValidationErrors={setValidationErrors}
                      validationType={
                        element?.fieldOptions?.formatValidation_value
                      }
                      numberOfCharacters={
                        element?.fieldOptions?.numberOfCharacters
                      }
                      type={element?.fieldOptions?.type_value}
                      helperText={element?.fieldOptions?.helpText}
                      fieldValues={fieldValues}
                      isShow={element?.fieldOptions?.isShow}
                    />
                  )}
                </Fragment>
              );
            }
          })}
        </div>
      ))}

      {/* All products */}
      {(formData?.formType === "filledUpForm" && pageContent?.rows && findProduct(pageContent?.rows)) && products?.length > 0 && (
        <ProductSection products={products} storeId={storeId} section="product" promotionalProductCountRef={promotionalProductCountRef} eachTypeProductCount={eachTypeProductCount} seteachTypeProductCount={seteachTypeProductCount} />
      )}

      {/* bundle products */}
      {(formData?.formType === "filledUpForm" && pageContent?.rows && findProduct(pageContent?.rows)) && bundleRequiredData?.length > 0 && (
        <ProductSection products={bundleRequiredData} storeId={storeId} section="bundleProduct" promotionalProductCountRef={promotionalProductCountRef} eachTypeProductCount={eachTypeProductCount} seteachTypeProductCount={seteachTypeProductCount} />
      )}

      {/* promotional products */}
      {promotionalProducts && promotionalProducts.length ? <ProductSection products={promotionalProducts} storeId={storeId} section="promotionalProduct" promotionalProductCountRef={promotionalProductCountRef} eachTypeProductCount={eachTypeProductCount} seteachTypeProductCount={seteachTypeProductCount} /> : <></>}

    </div>
  );
};

export default FormContent;
