import React from 'react'

export default function FooterProductView({ cartItemsObj }) {

    return (
        <div className="relative w-full border border-gray-200 py-[10px] rounded-md mb-[30px]">
            <div className='grid grid-cols-1 md:grid-cols-5 gap-[30px]'>
                <div className="flex justify-center items-center w-full overflow-hidden">
                    <img src={`${process.env.REACT_APP_imageBaseUrl}${cartItemsObj?.shoppingCartItemModel?.logogImagePath}`} alt="" className="max-h-[200px]" />
                </div>
                <div className="pl-[8px] pr-[8px] col-span-1 md:col-span-4 flex flex-col justify-center">
                    <p className="mt-[5px] h-auto font-semibold line-clamp-2 text-sm pb-[5px]">{cartItemsObj?.shoppingCartItemModel?.name}</p>
                    {/* <p className="text-gray-600 text-xs uppercase">msrp: <span className="text-gray-800 font-normal text-sm">$ {cartItemsObj?.shoppingCartItemModel?.price}</span></p> */}
                    <p className="text-gray-600 text-xs uppercase">sku: <span className="text-gray-800 font-normal text-sm">{cartItemsObj?.shoppingCartItemModel?.sku}</span></p>
                    <p className="text-gray-600 text-xs uppercase">color: <span className="text-gray-800 font-normal text-sm">{cartItemsObj?.shoppingCartItemsDetailModels[0]?.attributeOptionValue}</span></p>
                    <p className="text-gray-600 text-xs uppercase">size: <span className="text-gray-800 font-normal text-sm">{cartItemsObj?.cartLogoPersonModel[0]?.attributeOptionValue}</span></p>
                </div>
            </div>
        </div>
    )
}
