import React, { useContext, useState } from "react";
import { toast } from "react-toastify";

import Modal from "./Modal";
import { FormContext } from "../contexts/FormContext";

import { decryptPassword } from "../service/FormService";

export default function PasswordProtectedModal() {
  const { formData, setmodalState } = useContext(FormContext);

  const [pagePassword, setPagePassword] = useState("");

  const handleVerifyPassword = async () => {
    if (formData?.protectedPassword) {
      const formDataResponse = await decryptPassword(formData.protectedPassword);

      if (formDataResponse?.data == pagePassword) {
        setmodalState((prev) => ({ ...prev, isVisible: false }));
      } else {
        toast("invalid crediantials");
      }
    }
  };

  return (
    <Modal>
      <div className="w-full flex flex-col justify-center items-center">
        <p className="text-2xl my-4 text-left w-full">Enter The Password To Access This Page.</p>
        <input
          type="password"
          className="block w-full bg-white border border-neutral-200 hover:border-neutral-300 focus:border-neutral-300 focus:ring-0 focus:shadow-lg px-2 py-2 mb-4 rounded-md"
          placeholder="Enter Access Password"
          value={pagePassword}
          onChange={(e) => setPagePassword(e.target.value)}
        />

        <button
          className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          onClick={handleVerifyPassword}
        >
          Verify Password and Access Page
        </button>
      </div>
    </Modal>
  );
}
