export const domains = {
  // formUrl: "www.latest.pkhealthgearonline.site",
  // formUrl: "www.new.pkhealthgearonline.site",
  // formUrl: "https://cktest.pkhealthgearonline.site",
  // formUrl: "https://100thanniversarybook.pkhealthgearonline.site",
  // formUrl: "https://formnine.hnfbtest",
  // formUrl: "https://testckformbuilder.hnfbtest",
  // formUrl: "https://100thanniversarybook.pkhealthgearonline.site",
  // formUrl: "https://newformtotest01.hf.redefinecommerce.info",
  // formUrl: "https://ckforbuildertest-dnd.ckforbuildertest",
  // formUrl: "https://dell1.www.dell",
  // formUrl: "https://checkingthehack.www.dell",
  // formUrl: "https://redcrosshealth.pkhealthgear.site",
  // formUrl: "https://withpromotionalproducts.www.dell",
  // formUrl: "https://summitamericas.ckforbuildertest",
  // formUrl: "https://incentivesummitusa.pkhealthgearonline.site",
  // formUrl: "https://newformforvisahltest.ckformbetatest15-07-2024",
  // formUrl: "https://ckformbetachild.ckformbetatest15-07-2024",
  // formUrl: "https://dell-test.www.chandanstore",
  // formUrl: "https://testforalllabel.ckformbetatest15-07-2024",
  // formUrl: "https://newformtest0014.ckformbetatest15-07-2024",
  // formUrl: "https://testproductwithout.ckformbetatest15-07-2024",
  // formUrl: "https://testforaddressconcate.ckformbetatest15-07-2024",
  //formUrl: "https://vikas_sir_test_15.ckformbetatest15-07-2024",
  // formUrl: "https://vikas_sir_test_15.ckformbetatest15-07-2024",
  // formUrl: "https://testforalllabel.ckformbetatest15-07-2024",
  // formUrl: "https://testforselectionfrommultiplelocations+homeshipments.ckformbetatest15-07-2024",
  // formUrl: "https://samplestpr1.ckformbetatest15-07-2024",
  // formUrl: "https://requestproduct.ckgoodsservices.online",
  // formUrl: "https://form005.ckgoodsservices.online",
  formUrl: "https://samplstr.pkhealthgearonline.site",
};

// if we need to check out out in local thn make it false
export const isLive = true; // process.env.REACT_APP_IS_LIVE || false;
