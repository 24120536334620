import React, { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import {
  addOrder,
  addProductToCart,
  createFilledForms,
  fetchProducts,
  getFormBuilder,
  fetchPromotionalProducts,
  getBundleListByStoreId,
  getFormFields,
  getStoreDetailsByDomain,
  getThemeConfigs,
  fetchFormThemeAllConfigs,
} from "../service/FormService";
import { stringToJsonParser, checkRequiredFieldsEntered } from "utils/index";

import { domains, isLive } from "../config";

import { alertMessages } from "../constants/messages";
import { formTypes, getDistinctSubRows } from "../constants";
import { getDistinctObjects } from "../constants";

import { clearCache } from "service/FormService";

import { FormContext } from "../contexts/FormContext";
import BannerImageSection from "../components/section/pages/formBuilderPage/BannerImageSection";
import BannerText from "../components/section/pages/formBuilderPage/BannerText";
import FooterSection from "../components/section/pages/formBuilderPage/FooterSection";
import FormSection from "../components/section/pages/formBuilderPage/FormSection";
import Header from "../components/section/pages/formBuilderPage/Header";
import Loader from "../components/loader/Loader";

import Thankyou from "./Thankyou";
import PasswordProtectedModal from "../components/PasswordProtectedModal";

const FormBuilderPage = () => {
  const [searchParams] = useSearchParams();

  const [pageCount, setPageCount] = useState();
  const [fieldValues, setFieldValues] = useState();
  const [formData, setFormData] = useState({ productLimitCount: 0 });
  const [initialData, setInitialData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState(null);
  const [products, setProducts] = useState([]);
  const [promotionalProducts, setPromotionalProducts] = useState([]);
  const [bundleRequiredData, setBundleRequiredData] = useState([]);
  const [url, setUrl] = useState("");
  const [storeDetails, setStoreDetails] = useState({ storeId: null, formId: null });
  const [isUseTemplate, setIsUseTemplate] = useState(false);
  const [isCaptchaAvailable, setIsCaptchaAvailable] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [cartItems, setCartItems] = useState([]);

  const [formThemeData, setformThemeData] = useState({ contactInfo: null, cssType: null, customThemeFooter: null, mainThemeConfig: null });

  const promotionalProductCountRef = useRef(0)

  const [modalState, setmodalState] = useState({
    isVisible: false,
    title: "My Modal",
  });

  const [customShippingFormref, setcustomShippingFormref] = useState({})

  const fetchFormFieldAndThemeConfigs = async () => {
    try {
      setIsLoading(true);
      const formFieldResponse = await getFormFields(url);

      if (formFieldResponse.data) {
        const productResponse = await fetchProducts(formFieldResponse?.data?.formsId);
        const res = await fetchFormThemeAllConfigs(formFieldResponse.data?.formsId)

        if (productResponse?.data && Array.isArray(productResponse.data)) {
          const distinctObjects = getDistinctObjects(productResponse.data, 'id');

          setProducts(distinctObjects);
        }

        if (res) {
          const contactInfo = stringToJsonParser(`${res?.contact_info?.config_value}`).data
          const cssType = stringToJsonParser(`${res?.css_type?.config_value}`).data
          const customThemeFooter = stringToJsonParser(`${res?.custom_theme_footer?.config_value}`).data
          const mainThemeConfig = stringToJsonParser(`${res?.main_theme_config?.config_value}`).data

          setformThemeData({ contactInfo, cssType, customThemeFooter, mainThemeConfig })
        }

        const fieldValues = formFieldResponse?.data?.fieldValues;
        const parsedFieldValues = JSON.parse(fieldValues);

        setStoreDetails({
          storeId: formFieldResponse.data?.id,
          formId: formFieldResponse.data?.formsId,
        });

        setPageCount(parsedFieldValues?.pages?.length);
        addInitialFormDepedency(parsedFieldValues);

        const themeConfigRes = await getThemeConfigs(formFieldResponse?.data?.formsId, "");

        const themeConfigs = themeConfigRes?.data;

        if (themeConfigs?.length) {
          const bannerImage = themeConfigs.find(
            (config) => config.configName === "banner_image"
          )?.configValue;
          const topHtml = themeConfigs.find(
            (config) => config.configName === "top_text"
          )?.configValue;
          const footerHtml = themeConfigs.find(
            (config) => config.configName === "footer_text"
          )?.configValue;
          const logoPosition = themeConfigs.find(
            (config) => config.configName === "logo_position"
          )?.configValue;
          const formBgColor = themeConfigs.find(
            (config) => config.configName === "form_color"
          )?.configValue;
          const formFontFamily = themeConfigs.find(
            (config) => config.configName === "form_family"
          )?.configValue;
          const formLabelFontSize = themeConfigs.find(
            (config) => config.configName === "form_size"
          )?.configValue;
          const thankYouPageContent = themeConfigs.find(
            (config) => config.configName === "thankyou_text"
          )?.configValue;

          setFormData((prev) => ({
            ...prev,
            bannerImage,
            topHtml,
            footerHtml,
            logoPosition,
            formBgColor,
            formFontFamily,
            formLabelFontSize,
            thankYouPageContent: stringToJsonParser(thankYouPageContent).data,
          }));
        }
      }
    } catch (ex) {
      setIsLoading(false);
      console.log("exception in fetching form data--", ex);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFormData = async () => {
    try {
      setIsLoading(true);
      const formDataResponse = await getFormBuilder(url);

      if (formDataResponse && formDataResponse.success) {
        await fetchFormFieldAndThemeConfigs();

        if (formDataResponse?.data?.id) {
          // fetching products from bundles
          const bundleDataResponse = await getBundleListByStoreId(formDataResponse.data?.id);
          if (bundleDataResponse?.data && Array.isArray(bundleDataResponse.data)) {
            const distinctObjects = await getDistinctSubRows(bundleDataResponse.data, "subRows", 'id');
            if (distinctObjects.length) {
              setBundleRequiredData(distinctObjects);
            }
          }

          // here formProductId is related to promotional product added from admin
          if (formDataResponse?.data?.formProductId) {
            const PromotionalProductResponse = await fetchPromotionalProducts(formDataResponse.data?.id, formDataResponse?.data?.formProductId);

            if (PromotionalProductResponse?.data && Array.isArray(PromotionalProductResponse.data)) {
              const distinctObjects = await getDistinctObjects(PromotionalProductResponse.data, 'id');

              if (process.env.NODE_ENV === "development" && !distinctObjects.length) {
                setPromotionalProducts([]);
              } else {
                setPromotionalProducts(distinctObjects);
                promotionalProductCountRef.current = distinctObjects.length
              }

            }
          }

          if (formDataResponse?.data) {
            let tempShippingAddress = formDataResponse.data?.shippingAddresses

            if (parseInt(formDataResponse?.data?.payBusinessMethodId) === 3) {
              tempShippingAddress = [
                ...tempShippingAddress, {
                  "id": "0000",
                  "shipFirstName": "",
                  "shipLastName": "",
                  "shipCompany": "",
                  "shipAddress1": "",
                  "shipAddress2": "",
                  "shipCity": "",
                  "shipState": "",
                  "shipSuite": "",
                  "shipZipcode": "",
                  "shipCountry": 0,
                  "shipPhone": "",
                  "shipEmail": "",
                  "shipFax": null,
                  "isDefault": false,
                  "shipCountryName": "",
                  "addressTitle": "Other"
                }
              ]
            }


            if (formDataResponse.data.isPasswordProtected) {
              setmodalState((prev) => ({ ...prev, isVisible: true, title: "Encrepted Form Modal." }));
              toast("password is required");
            }

            setIsUseTemplate(formDataResponse?.data?.isUseTemplate);
            setFormData((prev) => ({
              ...prev,
              ...formDataResponse.data,
              logoUrl: formDataResponse.data?.formLogoPath,
              shippingAddresses: tempShippingAddress,
            }));
          }

          setIsLoading(false);
        }
      }
    } catch (ex) {
      setIsLoading("notActive");

      console.log("fetch form data exception----", ex);
    }
  };

  const handleSave = async () => {

    // check for captcha validation
    if (isCaptchaAvailable && !isCaptchaVerified) {
      toast(alertMessages.verifyCaptcha);

      return
    }

    // check for validation in form
    if (validationErrors.length) {
      return;
    }

    // check if all required fields are entered in form
    const { isValid } = checkRequiredFieldsEntered(fieldValues);

    if (!isValid) {
      toast(alertMessages.enterRequiredFields);
      return;
    }

    try {
      setIsLoading(true);
      const payload = {
        formBuilderFieldEntryModel: {
          rowVersion: "",
          location: "",
          ipAddress: "192.168.1.1",
          macAddress: "00-00-00-00-00-00",
          formsId: formData.id,
          fieldValues: "",
        },
      };

      // if form type is request form add shipping address to fiels values
      if (formData.formType === formTypes.requestForm) {
        payload.formBuilderFieldEntryModel.fieldValues = JSON.stringify({
          ...fieldValues,
          shipping: selectedShippingAddress || {},
        });

        const response = await createFilledForms(payload);

        if (response?.success) {
          setIsFormSubmitted(true);

          // toast(alertMessages.formSaved);
        }
      } else {
        if (cartItems.length || (selectedShippingAddress || Object.keys(customShippingFormref).length)) {
          const customerID = await handleAddCart();
          // return;
          if (customerID) {
            const orderTotal = cartItems.reduce(
              (acc, curr) => acc + curr?.shoppingCartItemModel?.price,
              0
            );

            const storeDetails_1 = await getStoreDetailsByDomain(url);

            const orderModel = {
              id: 0,
              storeID: storeDetails_1?.data?.id || -1,
              isNew: true,
              shoppingCartID: 0,
              customerID: customerID,
              firstName: selectedShippingAddress?.shipFirstName || customShippingFormref?.FirstName || "",
              lastName: selectedShippingAddress?.shipLastName || customShippingFormref?.LastName || "",
              email: selectedShippingAddress?.shipEmail || customShippingFormref?.ShippingEmail || "",
              paymentMethod: "PREPAYMENT",
              paymentGateway: "PREPAYMENT",
              notes: "",
              billingEqualsShipping: true,
              billingEmail: selectedShippingAddress?.shipEmail || customShippingFormref?.ShippingEmail || "",
              billingFirstName: selectedShippingAddress?.shipFirstName || customShippingFormref?.FirstName || "",
              billingLastName: selectedShippingAddress?.shipLastName || customShippingFormref?.LastName || "",
              billingCompany: selectedShippingAddress?.shipCompany || "",
              billingAddress1: selectedShippingAddress?.shipAddress1 || customShippingFormref?.ShippingAddress1 || "",
              billingAddress2: selectedShippingAddress?.shipAddress2 || customShippingFormref?.ShippingAddress2 || "",
              billingSuite: selectedShippingAddress?.shipSuite || customShippingFormref?.ShippingSuite || "",
              billingCity: selectedShippingAddress?.shipCity || customShippingFormref?.ShippingCity || "",
              billingState: selectedShippingAddress?.shipState || customShippingFormref?.ShippingState?.label || "",
              billingZip: selectedShippingAddress?.shipZipcode || customShippingFormref?.ShippingZip || "",
              billingCountry: selectedShippingAddress?.shipCountry || customShippingFormref?.ShippingCountry?.label || "",
              billingPhone: selectedShippingAddress?.shipPhone || customShippingFormref?.ShippingPhone || "",

              shippingEmail: selectedShippingAddress?.shipEmail || customShippingFormref?.ShippingEmail || "",
              shippingFirstName: selectedShippingAddress?.shipFirstName || customShippingFormref?.FirstName || "",
              shippingLastName: selectedShippingAddress?.shipLastName || customShippingFormref?.LastName || "",
              shippingCompany: selectedShippingAddress?.shipCompany || "",
              shippingAddress1: selectedShippingAddress?.shipAddress1 || customShippingFormref?.ShippingAddress1 || "",
              shippingAddress2: selectedShippingAddress?.shipAddress2 || customShippingFormref?.ShippingAddress2 || "",
              shippingSuite: selectedShippingAddress?.shipSuite || customShippingFormref?.ShippingSuite || "",
              shippingCity: selectedShippingAddress?.shipCity || customShippingFormref?.ShippingCity || "",
              shippingState: selectedShippingAddress?.shipState || customShippingFormref?.ShippingState?.label || "",
              shippingZip: selectedShippingAddress?.shipZipcode || customShippingFormref?.ShippingZip || "",
              shippingCountry: selectedShippingAddress?.shipCountry || customShippingFormref?.ShippingCountry?.label || "",
              shippingPhone: selectedShippingAddress?.shipPhone || customShippingFormref?.ShippingPhone || "",
              shippingMethod: "",

              okToEmail: true,
              cardName: "",
              cardType: "",
              cardNumber: "",
              cardVarificationCode: "",
              cardExpirationMonth: "",
              cardExpirationYear: "",
              couponCode: "",
              couponDiscountAmount: 0,
              giftCertiSerialNumber: "",
              giftCertificateDiscountAmount: 0,
              quantityDiscountAmount: 0,
              levelDiscountPercent: 0,
              levelDiscountAmount: 0,
              customDiscount: 0,
              orderSubtotal: orderTotal,
              orderTax: 0,
              orderShippingCosts: 0,
              orderTotal: orderTotal,
              authorizationCode: "",
              authorizationResult: "",
              authorizationPNREF: "",
              transactionCommand: "",
              lastIPAddress: "",
              orderStatus: "New",
              transactionStatus: "pending",
              avsResult: "",
              captureTxCommand: "",
              captureTXResult: "",
              authorizedOn: "2023-05-20T06:10:00.598Z",
              capturedOn: "2023-05-20T06:10:00.598Z",
              orderDate: new Date(),
              deleted: true,
              referrer: "",
              refundedAmount: 0,
              chargeAmount: 0,
              authorizedAmount: 0,
              adjustmentAmount: 0,
              orderNotes: "",
              isGiftWrap: true,
              giftWrapAmt: 0,
              inventoryWasReduced: true,
              refOrderID: "",
              isMobileOrder: true,
              batchId: 0,
              shippingLabelCost: 0,
              shippingLabelWeight1: 0,
              shippingLabelPackageHeight: 0,
              shippingLabelPackageWidth: 0,
              shippingLabelPackageLength: 0,
              noOfLabels: 0,
              salesAgentId: 0,
              isApproved: true,
              dimensionValue: 0,
              giftWrapPrice: 0,
              shipPromotionDiscount: 0,
              isFullFillment: true,
              isAmazonuplaod: true,
              cvvResult: "",
              isMailSend: true,
              shippedByStamps: true,
              logoFinalTotal: 0,
              lineFinalTotal: 0,
              isExport: true,
              inHandDate: new Date(),
              storeCredit: 0,
              chargeHostedPaymentID: "",
              sewout: true,
              sewoutTotal: 0,
              digitalTotal: 0,
              empSourceName: "",
              empSourceMedium: "",
              gclid: "",
              isPayLater: true,
              orderCheckoutNote: "",
              empSalesRap: "",
              employeeID: 0,
              isCreditLimit: false,
              endUserName: "",
              decorationDate: "2023-05-20T06:10:00.598Z",
              formId: storeDetails.formId,
              salesRepName: "",
            }

            const orderPlacingResponse = await addOrder({ orderModel });

            if (orderPlacingResponse) {
              payload["formBuilderFieldEntryModel"]["orderId"] = orderPlacingResponse.data.id

              fieldValues["cartItems"] = cartItems
              fieldValues["shippingInfo"] = (formData?.payBusinessMethodId === 2 && formData?.formType === "filledUpForm") ? customShippingFormref : selectedShippingAddress

              toast("order placed successfully.")

              // if form type is filled form call order api to submit shipping address and then call create filled form api
              payload.formBuilderFieldEntryModel.fieldValues = JSON.stringify(fieldValues);

              const resp = await createFilledForms(payload);

              if (resp?.success) {
                setIsFormSubmitted(true);

                // toast(alertMessages.formSaved);
              }

              setIsLoading(false);
            }
          }
        }
      }
    } catch (error) {
      console.log("exception: ", error);
    } finally {
      setIsLoading(false);
      setIsCaptchaVerified(false);
    }
  };

  const handleClearAll = () => {
    if (initialData?.pages?.length) setFieldValues(initialData);
  };

  const addInitialFormDepedency = (fv) => {
    if (fv) {
      const newFieldValues = JSON.parse(JSON.stringify(fv));
      newFieldValues.pages.forEach((page) => {
        page.rows.forEach((row) => {
          row.elements.forEach((element) => {
            if (element.name === "button" && element?.fieldOptions?.dependencies?.value) {
              const dependencies = element?.fieldOptions?.dependencies;
              const value = JSON.parse(dependencies.value);
              const formToAdd = value.pages[0];
              element.fieldOptions.value = [[formToAdd.rows]];
            }
            if (element?.name === "captcha") setIsCaptchaAvailable(true);
          });
        });
      });
      setInitialData(newFieldValues);
      setPageTitle(newFieldValues?.pages[0]?.page_name);
      setFieldValues(newFieldValues);
    }
  };

  const handleAddCart = async () => {
    try {
      const reversedCartItem = Array.isArray(cartItems) ? cartItems.reverse() : cartItems

      if (reversedCartItem && reversedCartItem?.length) {
        const firstItemPayload = {
          addToCartModel: reversedCartItem[0],
        };

        const customerId = await addProductToCart(firstItemPayload);
        if (customerId?.data) {
          const restCartItems = reversedCartItem.slice(1);
          const addtoCartPromises = restCartItems.map(async (item) => {
            const cartPayload = {
              addToCartModel: { ...item, customerId: customerId?.data },
            };
            return await addProductToCart(cartPayload);
          });
          await Promise.all(addtoCartPromises);
        }
        return customerId?.data;
      };


    } catch (error) {
      setIsLoading(false);
      console.log("exception in adding product: ", error);
      throw error;
    }
  };

  useEffect(() => {
    try {
      if (isLive === true) {
        const locationOrigin = window.location.origin;

        setUrl(locationOrigin);
      } else {
        setUrl(domains.formUrl.replaceAll(" ", ""));
      }
    } catch (error) {
      console.log("something went wrong ", error);
    }

    return () => {
      setIsFormSubmitted(false);
    };
  }, []);

  useEffect(() => {
    document.title = pageTitle;

    return () => {
      document.title = "";
    };
  }, [pageTitle]);

  useEffect(() => {
    if (url) {
      const favicon = document.getElementById("favicon");
      if (url.includes("pkhealthgear")) {
        favicon.href =
          "https://storagemedia.corporategear.com/storagemedia/1/store/favicon/favicon_7.png";
      } else if (url.includes("corporategear")) {
        favicon.href =
          "https://storagemedia.corporategear.com/storagemedia/1/store/favicon/favicon_5.png";
      } else if (url.includes("gamedaygear")) {
        favicon.href =
          "https://storagemedia.corporategear.com/storagemedia/1/store/favicon/favicon_6.png";
      } else if (url.includes("parsonskellogg")) {
        favicon.href = "/pasrsonKelloggFavicon.png";
      }
      fetchFormData();
    }
  }, [url]);

  useEffect(() => {
    if (formData && formData?.id) {
      const todoParam = searchParams.get('todo');

      if (todoParam === "clearcache") {
        clearCache(formData?.id, 0)
      }
    }
  }, [formData])

  return (
    <>
      {
        formData?.id ? <>
          <Helmet>
            <link
              rel="stylesheet"
              type="text/css"
              href={`${process.env.REACT_APP_imageBaseUrl}/${process.env.REACT_APP_Image_Directory}/${1}/store/${storeDetails.formId}/css/${storeDetails.formId}.css?${Math.random()}`}
            />
            <link
              rel="stylesheet"
              type="text/css"
              href={`${process.env.REACT_APP_imageBaseUrl}/${process.env.REACT_APP_Image_Directory}/${1}/store/theme.css?${Math.random()}`}
            />
            <link
              rel="stylesheet"
              type="text/css"
              href={`${process.env.REACT_APP_imageBaseUrl}/${process.env.REACT_APP_Image_Directory}/${1}/store/forms.css?${Math.random()}`}
            />
          </Helmet>
          <>

            <FormContext.Provider
              value={{
                storeId: storeDetails.storeId,
                formId: storeDetails.formId,
                isUseTemplate,
                setCartItems,
                cartItems,
                formData: formData,
                modalState,
                setmodalState,
                setcustomShippingFormref: setcustomShippingFormref
              }}
            >
              {isLoading && <Loader />}

              {formData?.isPasswordProtected && modalState.isVisible ? (
                <PasswordProtectedModal />
              ) : (
                <>
                  {Object.keys(formData).length > 1 && (
                    <div
                      className="bg-[#ffffff]"
                      style={{
                        fontFamily: formData?.formFontFamily || "bg-[#f5f5f6]",
                        background: formData?.formBgColor || " bg-[#ffffff]",
                      }}
                    >
                      {!isUseTemplate ? (
                        <Header
                          logoImageUrl={`${process.env.REACT_APP_imageBaseUrl}${formData.logoUrl}`}
                          position={formData.logoPosition}
                          formThemeData={formThemeData}
                        />
                      ) : <></>}

                      {/* form builder main section */}
                      <div className="min-h-[51.1vh]">
                        {isFormSubmitted ? (
                          <>
                            <div className="container mx-auto">
                              <div className="max-w-5xl mx-auto">
                                <Thankyou thankYouPageContent={formData?.thankYouPageContent} fieldValues={fieldValues} />
                              </div>
                            </div>
                          </>
                        ) : (
                          <section className="mainsection white-all white-link">
                            <div className="container mx-auto">
                              <div
                                className="relative mt-[30px]"
                                style={{
                                  backgroundColor: formData?.formBgColor || "#ffffff",
                                }}
                              >
                                {formData.bannerImage && (
                                  <BannerImageSection
                                    bannerImage={`${process.env.REACT_APP_imageBaseUrl}${formData.bannerImage}`}
                                  />
                                )}

                                {formData?.topHtml && <BannerText html={formData.topHtml} />}

                                <FormSection
                                  formLabelFontSize={formData?.formLabelFontSize}
                                  fieldValues={fieldValues}
                                  setFieldValues={setFieldValues}
                                  pageCount={pageCount}
                                  products={products || []}
                                  promotionalProducts={promotionalProducts}
                                  handleSave={handleSave}
                                  validationErrors={validationErrors}
                                  setValidationErrors={setValidationErrors}
                                  shipingAddresses={formData?.shippingAddresses || []}
                                  setSelectedShippingAddress={setSelectedShippingAddress}
                                  storeId={formData?.parentStoreId}
                                  handleClearAll={handleClearAll}
                                  setIsCaptchaVerified={setIsCaptchaVerified}
                                  isCaptchaAvailable={isCaptchaAvailable}
                                  setcustomShippingFormref={setcustomShippingFormref}
                                  selectedShippingAddress={selectedShippingAddress}
                                  customShippingFormref={customShippingFormref}
                                  cartItems={cartItems}
                                  promotionalProductCountRef={promotionalProductCountRef}
                                  bundleRequiredData={bundleRequiredData}
                                />
                              </div>
                            </div>
                          </section>
                        )}
                      </div>

                      {!isUseTemplate ? <FooterSection footerHtml={formData.footerHtml} formThemeData={formThemeData} /> : <></>}
                    </div>
                  )}
                </>
              )}
            </FormContext.Provider>
          </>
        </> : <div className="flex justify-center items-center min-h-screen text-2xl font-bold text-center ">{isLoading === "notActive" ? <p>Sorry but this form is not active at this moment.<br /> You need to talk to the customer support for further process.</p> : <p>Loading...</p>}</div>
      }

      <style dangerouslySetInnerHTML={{
        __html: `
      .btn-primary { background-color: #000 !important;
        color: #fff !important; }
        .btn-secondary { background-color: #000 !important;
          color: #fff !important; }
    `}} />

    </>
  );
};

export default FormBuilderPage;
