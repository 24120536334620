import Button from "../components/form/Button";
import CheckBox from "../components/form/CheckBox";
import DatePicker from "../components/form/DatePicker";
import Input from "../components/form/Input";
import LabelHeading from "../components/form/LabelHeading";
import RadioComponent from "../components/form/RadioComponent";
import Select from "../components/form/Select";
import Upload from "../components/form/Upload";

const fieldTypes = {
  date: DatePicker,
  checkbox: CheckBox,
  email: Input,
  textbox: Input,
  radiobutton: RadioComponent,
  dropdown: Select,
  state: Select,
  country: Select,
  button: Button,
  heading: LabelHeading,
  labelheading: LabelHeading,
  upload: Upload,
};

export default fieldTypes;
