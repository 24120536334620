import React, { useState, useEffect } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { paginateArray } from "utils/index";

const ReactSelectPaginate = ({
  defaultValue,
  options,
  name,
  isMulti = false,
  onChange,
  isSearchable = false,
  values,
  debounceTimeout = 0,
  optionOnSearch = false
}) => {
  const [defaultValueObj, setdefaultValueObj] = useState([]);
  const [FilteredOption, setFilteredOption] = useState([]);

  useEffect(() => {
    setFilteredOption([]);
    if (options && options.length) {

      if (
        defaultValue &&
        defaultValue.length &&
        (typeof defaultValue[0] == "number" ||
          typeof defaultValue[0] == "string")
      ) {
        const allFoundObj = [];
        defaultValue.forEach((value) => {
          const myresult = options.find((currentObj) => currentObj.value == value);

          if (myresult) {
            allFoundObj.push(myresult);
          }
        });
        setdefaultValueObj(allFoundObj);
      } else {
        setdefaultValueObj(defaultValue);
      }

      const filteredOption = options.filter((obj) => obj.label.trim());
      setFilteredOption(filteredOption);
    }
  }, [options, values?.storeId, defaultValue]);

  const LoadMoreOptionsFunc = (searchQuery, loadedOptions, { page }) => {
    if (searchQuery) {
      const myFoundObj = FilteredOption.filter((singleCusObj) =>
        singleCusObj.label.toLowerCase().includes(searchQuery.toLowerCase()),
      );

      return {
        options: myFoundObj,
        hasMore: false,
        additional: {
          page: 1,
        },
      };
    } else {
      if (optionOnSearch) {
        return {
          options: [],
          hasMore: loadedOptions.length < FilteredOption.length ? true : false,
          additional: {
            page: page + 1,
          },
        };
      } else {
        const OptionWithLimitedVal = paginateArray(
          FilteredOption,
          2 * page,
          page,
        );

        return {
          options: OptionWithLimitedVal,
          hasMore: loadedOptions.length < FilteredOption.length ? true : false,
          additional: {
            page: page + 1,
          },
        };
      }
    }
  };

  return (
    <div>
      {FilteredOption && FilteredOption.length ? (
        <AsyncPaginate
          id={name}
          loadOptions={LoadMoreOptionsFunc}
          value={defaultValueObj}
          getOptionValue={(option) => option.value}
          getOptionLabel={(option) => option.label}
          onChange={onChange}
          additional={{ page: 1 }}
          isSearchable={isSearchable}
          isMulti={isMulti}
          placeholder="Select an option"
          debounceTimeout={debounceTimeout}
        />
      ) : (
        <p className="flex justify-start items-center rounded-t sticky top-0 left-0 text-red-600 bg-white">
          No options to select.
        </p>
      )}
    </div>
  );
};

export default ReactSelectPaginate;
