import React from "react";

import FooterProductView from "components/section/pages/formBuilderPage/FooterProductView"
import FooterAddressView from "components/section/pages/formBuilderPage/FooterAddressView"
import FooterFilledCustomFormView from "components/section/pages/formBuilderPage/FooterFilledCustomFormView"

const Thankyou = ({ thankYouPageContent, fieldValues }) => {
  return <div className="text-2xl min-h-[30vh] max-w-[100vw] overflow-hidden break-words">

    <div className="my-4 text-center ">
      {
        thankYouPageContent?.thankYouTop ?
          <div
            className="w-full text-description"
            dangerouslySetInnerHTML={{ __html: thankYouPageContent?.thankYouTop }}
          /> : <h4>Thank you.</h4>
      }
    </div>

    {/* ordered items */}
    <div className="">
      <h1 className='my-4 text-lg font-bold'>Ordered items.</h1>
      {
        (fieldValues?.cartItems && Array.isArray(fieldValues?.cartItems)) ? fieldValues?.cartItems.map((cartItemsObj) => <FooterProductView cartItemsObj={cartItemsObj} />
        ) : <></>
      }
    </div>

    {/* Filled custom Form */}
    <div className="">
      <FooterFilledCustomFormView pages={fieldValues?.pages} />
    </div>

    {/* ordered shipping address */}
    <div className="">
      <h1 className='my-4 text-lg font-bold'>Shipping Address.</h1>
      <div className="flex gap-2">
        {
          fieldValues?.shippingInfo ? <FooterAddressView shippingInfo={fieldValues?.shippingInfo} /> : <span className="text-red-600">No shipping address to show.</span>
        }
      </div>
    </div>

    {
      thankYouPageContent?.thankYouBottom ? <div className="my-4 "> <div
        className="w-full text-description"
        dangerouslySetInnerHTML={{ __html: thankYouPageContent?.thankYouBottom }}
      />  </div> : <></>
    }

  </div>
};

export default Thankyou;
