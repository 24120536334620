import React, { useContext, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import CustomShippingAddress from "components/section/pages/formBuilderPage/CustomShippingAddress"

import FormContent from "../../../form/FormContent";
import PageTabs from "../../../form/PageTabs";
import { checkValidation } from "../../../../validations/checkValidation";
import ShippingAddressList from "./ShippingAddressList";
import { FormContext } from "../../../../contexts/FormContext";
import { CAPTCHA_KEY } from "../../../../constants";
import { toast } from "react-toastify";

const FormSection = ({
  fieldValues,
  setFieldValues,
  pageCount,
  products,
  handleSave,
  setValidationErrors,
  shipingAddresses,
  validationErrors,
  setSelectedShippingAddress,
  selectedShippingAddress,
  storeId,
  handleClearAll,
  formLabelFontSize,
  setIsCaptchaVerified,
  isCaptchaAvailable,
  promotionalProducts,
  setcustomShippingData,
  setcustomShippingFormref,
  cartItems,
  promotionalProductCountRef,
  bundleRequiredData
}) => {

  const { isUseTemplate, formData } = useContext(FormContext);
  const [currentPage, setCurrentPage] = useState(0);

  // ----------------- custom shipping -----------------------------

  const [customShippingFormData, setcustomShippingFormData] = useState({
    FirstName: "",
    LastName: "",
    ShippingAddress1: "",
    ShippingAddress2: "",
    ShippingSuite: "",
    ShippingZip: "",
    ShippingCity: "",
    ShippingCountry: { label: "", value: "" },
    ShippingState: { label: "", value: "" },
    ShippingPhone: "",
    ShippingEmail: ""
  });

  const [errors, setErrors] = useState({});

  const validateField = (name, value) => {
    const phoneNumberPattern = /^(?:\+1\s?)?(\([2-9]\d{2}\)|[2-9]\d{2})[\s.-]?\d{3}[\s.-]?\d{4}$/;
    // Example usage:
    // console.log(phoneNumberPattern.test("123-456-7890")); // false
    // console.log(phoneNumberPattern.test("223-456-7890")); // true
    // console.log(phoneNumberPattern.test("(223) 456-7890")); // true
    // console.log(phoneNumberPattern.test("+1 223-456-7890")); // true
    // console.log(phoneNumberPattern.test("+1 (223) 456-7890")); // true
    // console.log(phoneNumberPattern.test("223.456.7890")); // true

    let error = "";

    switch (name) {
      case "FirstName":
        if (!value) error = "First Name is required";
        break;
      case "LastName":
        if (!value) error = "Last Name is required";
        break;
      case "ShippingAddress1":
        if (!value) error = "Street Address is required";
        break;
      // case "ShippingSuite":
      //   if (!value) error = "APT, SUITE is required";
      //   break;
      case "ShippingZip":
        if (!value) error = "Zip Code is required";
        break;
      case "ShippingCity":
        if (!value) error = "City is required";
        break;
      case "ShippingCountry":
        if (!value || !value.value) error = "Country is required";
        break;
      case "ShippingState":
        if (!value || !value.value) error = "State is required";
        break;
      case "ShippingPhone":
        if (!value || !phoneNumberPattern.test(value)) {
          return error = "Enter Valid Phone Number. Format xxx-xxx-xxxx, xxx xxx xxxx, xxx.xxx.xxxx, xxxxxxxxxx'";
        }

        break;
      case "ShippingEmail":
        if (!value || !/\S+@\S+\.\S+/.test(value)) error = "Invalid email address";
        break;
      default:
        break;
    }

    setErrors(prevErrors => ({ ...prevErrors, [name]: error }));
    return error;
  };

  const handleChange = (name, value) => {

    // Clear the error for the field
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: ""
    }));

    // Update the form data with the new value
    setcustomShippingFormData(prevData => ({ ...prevData, [name]: value }));
    setSelectedShippingAddress((prev) => ({ ...prev, [name]: value }))

    // Validate the field with the new value
    validateField(name, value);
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    Object.keys(customShippingFormData).forEach(field => {
      const error = validateField(field, customShippingFormData[field]);
      if (error) {
        newErrors[field] = error;
        isValid = false;
      } else {
        newErrors[field] = ""; // Clear error if no validation error
      }
    });

    setErrors(newErrors); // Update all errors at once
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData?.formType === "filledUpForm") {

      function checkValidToCheck() {
        let currentSituation = false;

        if (cartItems.length) {
          if (parseInt(formData?.payBusinessMethodId) === 2) {
            currentSituation = validateForm()
          } else if (parseInt(formData?.payBusinessMethodId) === 3 && selectedShippingAddress?.id === "0000") {
            currentSituation = validateForm()
          } else if (selectedShippingAddress?.id) {
            currentSituation = true
          }
        }

        return currentSituation
      }


      if (checkValidToCheck()) {
        setcustomShippingFormref(customShippingFormData);
        handleSave()

      } else {
        toast("Please select min 1 product and fill all the required fields.")
      }

    } else {
      handleSave()
    }
  };


  // ----------------- custom shipping -----------------------------


  const handleInputChange = (id, value, validations) => {
    if (Object.keys(validations).length) {
      // validation starts
      const { required, name, type, minLength } = validations;
      // check for validation based on type
      const errorMessage = checkValidation(
        { name, required, type, minLength },
        value
      );
      // if validation fails
      if (errorMessage) {
        const foundError = validationErrors.find((el) => el.id === id);
        // if error already present change the message (because validations could be multiple)

        if (foundError) {
          foundError.message = errorMessage;
          setValidationErrors(() =>
            validationErrors.map((ele) => {
              if (ele.id === id) {
                return { id, message: errorMessage };
              } else {
                return ele;
              }
            })
          );
        } else {
          // if error not already present add it
          setValidationErrors([
            ...validationErrors,
            { id, message: errorMessage },
          ]);
        }
      } else {
        // if validation succeed remove the object from validation error
        const filteredErrors = validationErrors.filter((el) => el.id !== id);
        setValidationErrors(filteredErrors);
      }
      // validation ends
    }

    // const allFieldValues = { ...fieldValues };
    const allFieldValues = JSON.parse(JSON.stringify(fieldValues));

    // find the current element and update it
    allFieldValues.pages[currentPage].rows.forEach((row, index) => {
      row.elements.forEach((element) => {
        if (element?.id === id) {
          element.fieldOptions.value = value;
          // if element has dependency
          const dependency = element?.fieldOptions?.dependencies;
          const dependentElement = dependency?.element;
          if (dependentElement) {
            // check if selected value is the value of the dependency
            if (dependency?.value === value) {
              // if element is not already added, add it
              let elementFound = false;
              allFieldValues.pages[currentPage].rows.forEach((row) => {
                if (
                  row.elements.some((ele) => ele.id === dependentElement.id)
                ) {
                  elementFound = true;
                  return;
                }
              });
              if (!elementFound) {
                allFieldValues.pages[currentPage].rows.splice(index + 1, 0, {
                  elements: [dependentElement],
                });
              }
            } else {
              // if selected value is not the value of dependency remove that element
              const filteredRows = allFieldValues.pages[
                currentPage
              ].rows.filter((row) => {
                return !row.elements.some(
                  (el) => el.id === dependentElement.id
                );
              });
              allFieldValues.pages[currentPage].rows = filteredRows;
            }
          }
        }
      });
    });

    setFieldValues(allFieldValues);
  };

  return (
    <div>
      {pageCount > 1 && (
        <PageTabs
          pageCount={pageCount}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      )}

      <FormContent
        pageContent={fieldValues?.pages[currentPage]}
        handleInputChange={handleInputChange}
        setValidationErrors={setValidationErrors}
        validationErrors={validationErrors}
        fieldValues={fieldValues}
        currentPage={currentPage}
        setFieldValues={setFieldValues}
        formLabelFontSize={formLabelFontSize}
        products={products}
        storeId={storeId}
        promotionalProducts={promotionalProducts}
        promotionalProductCountRef={promotionalProductCountRef}
        bundleRequiredData={bundleRequiredData}
      />

      {
        formData?.payBusinessMethodId === 2 && formData?.formType === "filledUpForm" ? <div className="mt-6">
          <CustomShippingAddress setcustomShippingData={setcustomShippingData}
            customShippingFormData={customShippingFormData}
            setcustomShippingFormData={setcustomShippingFormData}
            errors={errors}
            setErrors={setErrors}
            handleChange={handleChange}
            setSelectedShippingAddress={setSelectedShippingAddress}
          />
        </div> : <>
          {shipingAddresses?.length > 0 && !isUseTemplate && (
            <>{
              (formData?.shippingAddresses && formData?.shippingAddresses[0]?.shipAddress1 && formData?.shippingAddresses[0]?.shipCity) ?
                <ShippingAddressList
                  addressList={shipingAddresses}
                  setSelectedShippingAddress={setSelectedShippingAddress}
                /> : <></>}
            </>
          )}
        </>
      }

      {
        selectedShippingAddress?.id === "0000" ? <div className="mt-6"> <CustomShippingAddress setcustomShippingData={setcustomShippingData}
          customShippingFormData={customShippingFormData}
          setcustomShippingFormData={setcustomShippingFormData}
          errors={errors}
          setErrors={setErrors}
          handleChange={handleChange}
        /> </div> : <></>
      }

      {isCaptchaAvailable && (
        <div className="flex justify-start my-5">
          <ReCAPTCHA
            sitekey={CAPTCHA_KEY}
            onChange={(value) => setIsCaptchaVerified(!!value)}
          />
        </div>
      )}
      <div className="flex flex-wrap mx-[-15px] justify-center">
        <div className="w-6/12 px-[15px] mt-[10px] mb-[50px] flex justify-center gap-x-[20px]">
          <button onClick={handleSubmit} className="btn btn-secondary btn-lg">
            Submit
          </button>
          <button onClick={handleClearAll} className="btn btn-secondary btn-lg hidden">
            Clear
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormSection;
