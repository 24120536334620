import React from 'react'

export default function FooterAddressView({ shippingInfo }) {
    const keysToRemove = ["id", "shipFax", "isDefault", "ShippingCountry", "ShippingStatem", "ShippingAddress2", "shipAddress2", "shipCountry", "countryId", "stateId", "cityId", "ShippingZip"];

    const filteredObject = Object.fromEntries(
        Object.entries(shippingInfo).filter(([key, value]) => !keysToRemove.includes(key) && value !== "" && value !== null && value !== undefined)
    );

    const ShippingState = (shippingInfo?.ShippingState && shippingInfo?.ShippingState?.label) ? `${shippingInfo["ShippingState"]["label"]}` : ""

    return (
        <div className="relative w-full pb-[20px] rounded-md">
            <div className="grid grid-cols-2 md:grid-cols-2">
                {Object.entries(filteredObject).map((shippingRow, index) => <div className="mb-[10px] text-lg" key={index}>
                    <p className="text-gray-600 text-xs">
                        <strong className="uppercase">{`${shippingRow[0]}`.replace(/([a-z])([A-Z])/g, '$1 $2')}</strong>
                        : <span className="text-gray-800 font-normal text-sm">{`${shippingRow[0] === "ShippingState" ? ShippingState : shippingRow[1]}` || "____"}</span></p>
                </div>)}
            </div>
        </div>
    )
}
