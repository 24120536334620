import ShippingAddress from "../../../ShippingAddress";

const ShippingSection = ({ addressList, setSelectedShippingAddress }) => {
  return (
    <div className="relative w-full">
      <p className="text-xl mt-3 text-black font-bold">
        Please Choose Shipping Address <span className="text-rose-500 text-2xl leading-none">*</span>
      </p>
      <ul
        className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[15px] my-[36px]"
      >
        {addressList.map((address, index) => (
          <li className="text-center" key={index}>
            <ShippingAddress
              address={address}
              setSelectedShippingAddress={setSelectedShippingAddress}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ShippingSection;
